import { getCharacterGrid } from './characterGrids';

const invaderGap = 10;

export const initializeGame = (
  cellSize,
  playerRef,
  canvasSize,
  invadersRef,
  bulletsRef,
) => {
  // Initialize player

  const playerWidth = cellSize * 5; // Set player width proportional to cellSize
  const playerHeight = cellSize * 3; // Set player height proportional to cellSize

  playerRef.current = {
    width: playerWidth,
    height: playerHeight,
    x: canvasSize.width / 2 - 25, // Center the player horizontally
    y: canvasSize.height - 40, // Position the player at the bottom
    speed: 5,
    dx: 0,
  };

  const invaderText = 'code enthusiast';
  const invaderRows = 5;
  const invaderCols = invaderText.length;
  const invaderWidth = cellSize * 5;
  const invaderHeight = cellSize * 6;

  // Initialize invaders
  let invaders = [];
  for (let row = 0; row < invaderRows; row++) {
    for (let col = 0; col < invaderCols; col++) {
      const charGrid = getCharacterGrid(invaderText[col]);
      if (charGrid.length > 0) {
        invaders.push({
          x: col * (invaderWidth + cellSize + invaderGap),
          y: row * (invaderHeight + cellSize + invaderGap),
          width: invaderWidth,
          height: invaderHeight,
          charGrid,
        });
      }
    }
  }
  invadersRef.current = invaders;

  // Clear bullets
  bulletsRef.current = [];
};

export const updateGame = (
  ctx,
  playerRef,
  invadersRef,
  bulletsRef,
  canvas,
  cellSize,
  invaderSpeedRef,
  shoot,
  initializeGame,
) => {
  const drawPlayer = () => {
    const player = playerRef.current;
    ctx.fillStyle = '#FFECEC';
    ctx.fillRect(player.x, player.y, player.width, player.height);
  };

  const drawBullets = () => {
    ctx.fillStyle = '#FFECEC';
    bulletsRef.current.forEach((bullet) => {
      ctx.fillRect(bullet.x, bullet.y, bullet.width, bullet.height);
      bullet.y -= bullet.speed;
    });
  };

  const drawInvaders = () => {
    ctx.fillStyle = '#FFECEC';
    invadersRef.current.forEach((invader) => {
      invader.charGrid.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
          if (cell) {
            ctx.fillRect(
              invader.x + colIndex * cellSize,
              invader.y + rowIndex * cellSize,
              cellSize,
              cellSize,
            );
          }
        });
      });
    });
  };

  const movePlayer = () => {
    const player = playerRef.current;
    player.x += player.dx;

    if (player.x < 0) player.x = 0;
    if (player.x + player.width > canvas.width)
      player.x = canvas.width - player.width;
  };

  const moveInvaders = () => {
    let edgeReached = false;
    invadersRef.current.forEach((invader) => {
      invader.x += invaderSpeedRef.current;
      if (invader.x + invader.width > canvas.width || invader.x < 0) {
        edgeReached = true;
      }
    });

    if (edgeReached) {
      invaderSpeedRef.current *= -1;
      invadersRef.current.forEach((invader) => {
        invader.y += 30;
      });
    }
  };

  const detectCollisions = () => {
    bulletsRef.current.forEach((bullet, bulletIndex) => {
      invadersRef.current.forEach((invader, invaderIndex) => {
        if (
          bullet.x < invader.x + invader.width &&
          bullet.x + bullet.width > invader.x &&
          bullet.y < invader.y + invader.height &&
          bullet.y + bullet.height > invader.y
        ) {
          bulletsRef.current.splice(bulletIndex, 1);
          invadersRef.current.splice(invaderIndex, 1);
        }
      });
    });

    bulletsRef.current.forEach((bullet, index) => {
      if (bullet.y < 0) {
        bulletsRef.current.splice(index, 1);
      }
    });
  };

  const detectPlayerInvaderCollision = () => {
    const player = playerRef.current;
    return invadersRef.current.some((invader) => {
      return (
        player.x < invader.x + invader.width &&
        player.x + player.width > invader.x &&
        player.y < invader.y + invader.height &&
        player.y + player.height > invader.y
      );
    });
  };

  const update = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    movePlayer();
    moveInvaders();
    detectCollisions();

    if (
      detectPlayerInvaderCollision() ||
      invadersRef.current.length === 0
    ) {
      initializeGame();
    } else {
      drawPlayer();
      drawBullets();
      drawInvaders();
    }

    requestAnimationFrame(update);
  };

  update();
};

export const handleKeyDown = (e, playerRef, shoot) => {
  const player = playerRef.current;
  if (e.key === 'ArrowRight' || e.key === 'Right') {
    player.dx = player.speed;
  } else if (e.key === 'ArrowLeft' || e.key === 'Left') {
    player.dx = -player.speed;
  } else if (e.key === ' ' || e.key === 'Spacebar') {
    shoot();
  }
};

export const handleKeyUp = (e, playerRef) => {
  const player = playerRef.current;
  if (
    e.key === 'ArrowRight' ||
    e.key === 'Right' ||
    e.key === 'ArrowLeft' ||
    e.key === 'Left'
  ) {
    player.dx = 0;
  }
};

export const shoot = (playerRef, bulletsRef, cellSize) => {
  const player = playerRef.current;
  bulletsRef.current.push({
    x: player.x + player.width / 2 - 2.5,
    y: player.y,
    width: cellSize * 0.8,
    height: cellSize * 1.5,
    speed: 7,
  });
};
export const calculateCellSize = (containerWidth) => {
  const invaderText = 'code enthusiast';
  const maxCharsInRow = invaderText.length;
  const maxInvaderWidth = containerWidth * 0.4; // Use 80% of the container width for invaders
  return maxInvaderWidth / (maxCharsInRow * 5); // 5 cells wide for each character
};

export const resizeCanvas = (
  containerRef,
  setCellSize,
  setCanvasSize,
) => {
  if (containerRef.current) {
    const containerRect = containerRef.current.getBoundingClientRect();
    const newCellSize = calculateCellSize(containerRect.width);
    setCellSize(newCellSize);
    setCanvasSize({
      width: containerRect.width,
      height: containerRect.height,
    });
  }
};

export const drawPlayer = (playerRef, canvas2dContext) => {
  const player = playerRef.current;
  canvas2dContext.fillStyle = '#FFECEC';
  canvas2dContext.fillRect(
    player.x,
    player.y,
    player.width,
    player.height,
  );
};

export const drawBullets = (bulletsRef, canvas2dContext) => {
  canvas2dContext.fillStyle = '#FFECEC';
  bulletsRef.current.forEach((bullet) => {
    canvas2dContext.fillRect(
      bullet.x,
      bullet.y,
      bullet.width,
      bullet.height,
    );
    bullet.y -= bullet.speed;
  });
};

export const drawInvaders = (
  invadersRef,
  cellSize,
  canvas2dContext,
) => {
  canvas2dContext.fillStyle = '#FFECEC';
  invadersRef.current.forEach((invader) => {
    invader.charGrid.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (cell) {
          canvas2dContext.fillRect(
            invader.x + colIndex * cellSize,
            invader.y + rowIndex * cellSize,
            cellSize,
            cellSize,
          );
        }
      });
    });
  });
};

export const movePlayer = (playerRef, canvas) => {
  const player = playerRef.current;
  player.x += player.dx;

  if (player.x < 0) player.x = 0;
  if (player.x + player.width > canvas.width)
    player.x = canvas.width - player.width;
};

export const moveInvaders = (
  invadersRef,
  invaderSpeedRef,
  canvas,
) => {
  let edgeReached = false;
  invadersRef.current.forEach((invader) => {
    invader.x += invaderSpeedRef.current;
    if (invader.x + invader.width > canvas.width || invader.x < 0) {
      edgeReached = true;
    }
  });

  if (edgeReached) {
    invaderSpeedRef.current *= -1;
    invadersRef.current.forEach((invader) => {
      invader.y += 30; // Move down by invader height
    });
  }
};

export const detectPlayerInvaderCollision = (
  playerRef,
  invadersRef,
) => {
  const player = playerRef.current;
  return invadersRef.current.some((invader) => {
    return (
      player.x < invader.x + invader.width &&
      player.x + player.width > invader.x &&
      player.y < invader.y + invader.height &&
      player.y + player.height > invader.y
    );
  });
};

export const detectCollisions = (bulletsRef, invadersRef) => {
  bulletsRef.current.forEach((bullet, bulletIndex) => {
    invadersRef.current.forEach((invader, invaderIndex) => {
      if (
        bullet.x < invader.x + invader.width &&
        bullet.x + bullet.width > invader.x &&
        bullet.y < invader.y + invader.height &&
        bullet.y + bullet.height > invader.y
      ) {
        bulletsRef.current.splice(bulletIndex, 1);
        invadersRef.current.splice(invaderIndex, 1);
      }
    });
  });

  bulletsRef.current.forEach((bullet, index) => {
    if (bullet.y < 0) {
      bulletsRef.current.splice(index, 1);
    }
  });
};
