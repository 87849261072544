import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles({
  FooterContainer: {
    height: '10%',
    width: '100%',
    background: 'var(--color-white-medium)',
  },

  footerText: {
    color: 'var(--color-primary-dark)',
    margin: '0 1rem',
  },
});

const date = new Date();
const currentYear = date.getFullYear();

const Header = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.FooterContainer}
      height="10%"
    >
      <Typography className={classes.footerText} variant="h5">
        Alex Krippner
      </Typography>
      <IconButton href="https://www.linkedin.com/in/alexander-krippner-5a477019a/">
        <LinkedInIcon
          className={classes.footerText}
          fontSize="large"
        />
      </IconButton>
      <IconButton href="https://github.com/alex-krippner">
        <GitHubIcon className={classes.footerText} fontSize="large" />
      </IconButton>
    </Grid>
  );
};

export default Header;
